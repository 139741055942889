/*
  
  SASS Imports

*/

$frame-size: 10vh;

$breakpoints: (
  "mobile" "(max-width: 745px)",
  "tablet" "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 701px) and (min-height: 746px)",
  "desktop" "(min-width: 1024px)",
  "desktop-plus" "(min-width: 1200px)",
  "wide" "(min-width: 1400px)",
  "shallow" "(min-width: 1024px) and (max-height: 850px)",
  "iphone5"
    "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)"
);

@import "./sass/_mixins.scss";
@import "./sass/_animations.scss";

@font-face {
  font-family: "Untitled";
  src: url("font/Untitled/UntitledSans-Regular.woff2") format("woff2"),
    url("font/Untitled/UntitledSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Untitled";
  src: url("font/Untitled/UntitledSans-Medium.woff2") format("woff2"),
    url("font/Untitled/UntitledSans-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Untitled";
  src: url("font/Untitled/UntitledSans-RegularItalic.woff2") format("woff2"),
    url("font/Untitled/UntitledSans-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

* {
  box-sizing: border-box;
}

$base-font-family: "Untitled", sans-serif;
$base-font-family-alt: "Untitled", sans-serif;

$base-padding: 1rem;

%u-text--caps {
  font-family: $base-font-family-alt;
  font-weight: normal;
  font-size: 0.7rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

%u-text--story {
  font-family: $base-font-family;
  font-size: 3vh;

  @include respond-to("mobile") {
    font-size: 1.5rem;
  }
}

html,
body {
  color: #fff;
  overflow: hidden;
  background: #000;
  padding: 0;
  margin: 0;

  font-family: $base-font-family;
}

html {
  font-size: 16px;
}

strong {
  font-weight: bold;
}

html,
body {
  overscroll-behavior-x: none;
}

body {
  @extend %u-text--story;
}

#logo {
  #render {
    & > div {
      opacity: 0;
      animation: fadeIn 1.5s 1s ease forwards;
    }
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);

    max-width: 300px;

    @include respond-to("tablet-up") {
      max-width: 1000px;
    }

    canvas {
      width: 100% !important;
      height: auto;
      transform: scale(0.9);
    }
  }

  &.Logo--short {
    #render {
      transform: translate(-50%, -50%);

      max-width: 300px;
    }
  }
}

a {
  transition: border 0.6s ease;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  .no-touch & {
    &:hover {
      border-color: currentColor;
    }
  }
}

.Content a {
  transition: border 0.6s ease;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid;

  .no-touch & {
    &:hover {
      border-color: transparent;
    }
  }
}

.Content p {
  line-height: 140%;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

/*

Layers

*/

.Layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Layer--tiles {
  z-index: 0;
}

.Layer--profile {
  z-index: 4;
}

.Layer--project {
  z-index: 5;
}

/*

Page

*/

.Page,
.Page-scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Page {
  &:before,
  &:after {
    content: "";
    height: 100px;
    width: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &:after {
    top: auto;
    bottom: 0;
    height: 200px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.94) 40%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.Page-scroll {
  overflow-y: auto;

  z-index: 1;
}

.Page-story {
  white-space: nowrap;
  & > * > * {
    white-space: normal;
  }

  &:before {
    content: "";
    width: 0.1%;
    height: 100vh;
    display: inline-block;
    vertical-align: middle;
  }

  & > *:first-child {
    display: inline-block;
    width: 99.9%;
    vertical-align: middle;
  }
}

.is-touch .Page-scroll {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.Page {
  .Page-paper {
    background: black;

    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2), 0 50px 100px rgba(0, 0, 0, 0.5);
    min-height: 100vh;

    * {
      color: white;
    }
  }

  .Page-blackout {
    height: 100vh;
    background: black;
  }

  .Page-close {
    position: absolute;
    top: 13px;
    left: 25px;

    transform: translate(-50%, 0);

    z-index: 2;

    width: 30px;
    height: 30px;
    border-radius: 20px;

    transition: transform 0.4s ease;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 20px;
      height: 20px;
      path {
        stroke: #fff;
      }
    }
  }

  .Page-body {
    padding-bottom: $frame-size;
  }
}

/* Site */

.Site-strapline {
  position: fixed;
  top: 0;
  left: 0;
  width: 44%;
  padding: $base-padding * 1.5;
  z-index: 9;

  @include respond-to("mobile") {
    padding: 12px;
  }

  a {
    border: none !important;
  }

  display: none;
}

.Site-info {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  padding: $base-padding * 1.5;
  @include respond-to("mobile") {
    padding: 12px;
  }
  padding-left: 0;
  z-index: 9;
  text-align: right;
}

.Site-address {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  padding: $base-padding * 1.5;
  @include respond-to("mobile") {
    padding: 12px;
    padding-right: 0;
    padding-bottom: 20px;
  }
  padding-right: 0;
  z-index: 9;
}

.Site-nav {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: $base-padding * 1.5;
  @include respond-to("mobile") {
    padding: 12px;
    padding-bottom: 20px;
  }

  z-index: 9;
}

.Site-canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  //transform: scale(0.75);
}

.Logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.Logo-pixi {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.Logo-pixi canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.Site-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/assets/img/bg-lores.jpg") center center no-repeat;
  background-size: cover;
  opacity: 0;
  z-index: 1;
  animation: fadeIn 1s 1s ease forwards;
}

.Site-bg-wrap {
  opacity: 0.8;
}

/* Content */

.Content--story {
  padding: 10vh 10vh;
  text-align: center;
  p {
    max-width: 28em;
    margin-left: auto;
    margin-right: auto;
  }

  @include respond-to("mobile") {
    padding: 12vmax 30px;
  }

  @extend %u-text--story;
  max-width: 900px;
  margin: 0 auto;
}

/* List */

.List--nav {
  display: flex;
  justify-content: space-between;
  a {
    display: inline-block;
    position: relative;
  }
  li {
    &:first-child {
      min-width: 4em;
      @include respond-to("mobile") {
        position: fixed;
        top: 0;
        left: 0;
        padding: $base-padding;
        min-width: 10em;
      }
    }
  }
}
