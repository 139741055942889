html,
body {
	margin: 0;
	overflow: hidden;
}
.App {
	font-family: sans-serif;
	text-align: center;
}

#drop > span,
#drop .ant-upload {
	display: block;
}

#view {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
}

#noisemap {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 200px !important;
	height: 200px !important;
}
